/* custom */
.m-small {
  font-size: 90%;
}
.main-container {
  min-height: 100vh;
}
.max-width-500 {
  max-width: 500px;
}
.max-width-600 {
  max-width: 600px;
}
.max-width-700 {
  max-width: 700px;
}
.freelance-table {
  table-layout: fixed;
}
.freelance-table .table-name-heading {
  width: 50%;
}
.freelance-table thead {
  background-color: rgb(237, 242, 249);
}
.freelance-table thead th {
  color: #5e6e82;
  padding: 6px;
  font-weight: 500;
  font-size: 80%;
}
.freelance-table tbody a {
  color: #4a5073;
  font-weight: 500;
}
.freelance-table tbody a:hover {
  text-decoration: underline;
}
.freelance-table-sm thead {
  background-color: transparent;
}
.freelance-table-sm thead th {
  padding: 0 6px;
  border-top: 1px solid #ecedee;
}
.task-list-row td {
  border: 0 none;
}
.overview-task-list {
  max-height: 800px;
  overflow-y: scroll;
}
.task-list-row-container {
  box-shadow: 1px 1px 7px 0px #d8d6d6;
}
.task-list-row-container table tr:hover {
  background-color: #f7f7f7;
}
.freelace-calendar .react-datetime-picker__wrapper {
  border: 0 none;
}
.task-list-row-outer {
  position: relative;
}
.show-more-task-times-btn {
  background: linear-gradient(
    0deg,
    rgba(237, 242, 249, 0.6) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.cut-text-container {
  max-width: 90%;
}
.cut-text {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
/* default theme oveerride */
.sidebar .nav-link {
  font-size: 0.9rem;
}
.sidebar .nav-item:hover > .nav-link,
.sidebar .nav-item:focus > .nav-link,
.sidebar .nav-item.active > .nav-link {
  color: #16191c;
  background-color: transparent;
  text-decoration: underline;
}
.table-no-padding-bottom td {
  padding-bottom: 0;
}
.btn-outline-light {
  border-color: #d8dadd;
  color: #4a5073;
}
.form-control-sm {
  font-size: 0.875rem;
}
.btn-sm,
.btn-group-sm > .btn {
  line-height: 1.2;
}
/* task times */
.task-list-row table tr {
  position: relative;
}

.task-list-row table tbody tr td:first-of-type:before {
  content: "";
  position: absolute;
  border-left: 1px solid #ecedee;
  width: 8px;
  height: 100%;
  top: 0;
  left: 0px;
}
.task-list-row table tbody tr:last-child td:first-of-type:before {
  height: 50%;
}

.task-list-row table tbody tr td:first-of-type:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 50%;
  border-bottom: 1px solid #ecedee;
  top: 0;
  left: 0px;
}

.task-list-row table tbody tr td:first-of-type > * {
  margin-left: 10px;
}

.search {
  height: calc(1.5em + 0.5625rem);
}
.search input:placeholder-shown {
  text-overflow: ellipsis;
}


/* fixed menu */
#sidebarMenu.show {
  background-color: #fff;
}
/* tmp table fix */
.table-responsive {
  display: table;
}
.additional-comments {
  white-space: break-spaces;
  word-break: break-word;
}
@media (max-width: 992px) {
  .table-responsive {
    display: block;
  }
}
